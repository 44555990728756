import RetailerAPI from '@/api/retailers'
// initial state
const state = () => ({
  retailers: [],
  filteredRetailersByGeo: [],
  selected: null
})

// getters
const getters = {
  selected: (state) => state.selected,
  retailers: (state) => state.retailers,
  filteredRetailersByGeo: (state) => state.filteredRetailersByGeo
}

// actions
const actions = {
  async loadRetailers ({ commit }) {
    const result = await RetailerAPI.loadRetailers({geo: null})
    commit('setRetailers', result.data)
    commit('setFilteredRetailersByGeo', result.data)
  },
  setGeoFiltered({ commit }, filtered) {
    commit('setFilteredRetailersByGeo', filtered)
  },
  setSelected({ commit }, selected) {
    commit('setSelected', selected)
  }
}

// mutations
const mutations = {
  setRetailers (state, retailers) {
    state.retailers = retailers
  },
  setSelected(state, selected) {
    state.selected = selected
  },
  setFilteredRetailersByGeo(state, retailers) {
    state.filteredRetailersByGeo = retailers
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
