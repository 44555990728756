import store from '@/store'
import { lazyLoad } from '../../index'
import Artists from './artists'
import Artworks from './artworks'
import Products from './products'
import ProductTypes from './productTypes'
import ProductTypeVariants from './productTypeVariants'
import Users from './users'

export default {
  path: 'data',
  component: lazyLoad('Admin/Data/layout'),
  redirect: 'data/products',
  children: [
    Artists,
    Artworks,
    Products,
    ProductTypes,
    ProductTypeVariants,
    Users
  ]
}
