import { lazyLoad } from '../../index'
import ReportRoutes from './reportRoutes'
import RouterView from '@/components/RouterView'
import CustomerRoutes from './customerRoutes'
import OrderRoutes from './orderRoutes'
import MarketingRoutes from './marketingRoutes'

export default {
  path: 'business',
  redirect: 'business/customers',
  component: RouterView,
  children: [
    OrderRoutes,
    CustomerRoutes,
    ReportRoutes,
    MarketingRoutes
  ]
}
