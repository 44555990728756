import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import CustomerRoutes from './customerRoutes'
import PublishedArtistRoutes from './publishedArtistRoutes'
import AdminRoutes from './admin'
import UserRoutes from './user'
import RouterView from '@/components/RouterView'

Vue.use(Router)

export function lazyLoad(path) {
  return () => import(`@/views/${path}`)
}

// eslint-disable-next-line prefer-const
let router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      component: RouterView,
      children: [
        {
          path: '',
          meta: { landingPage: true },
          component: lazyLoad('Public/Landing')
        },
        {
          path: '/visitorattractions',
          meta: { landingPage: true },
          component: lazyLoad('Public/VisitorAttractions')
        },
        {
          path: '/home',
          component: lazyLoad('HomePage')
        },
        {
          path: '/register',
          component: lazyLoad('Public/Registration/Register')
        },
        {
          path: '/resetpassword/:token',
          component: lazyLoad('Public/ResetPassword')
        },
        {
          path: '/managesubscriptions/:token',
          component: lazyLoad('Public/ManageSubscriptions')
        },
        {
          path: '/requestpasswordreset',
          component: lazyLoad('Public/RequestPasswordReset')
        },
        {
          path: '/login',
          beforeEnter: (to, from, next) => {
            store.getters.isLoggedIn ? next('/home') : next()
          },
          component: lazyLoad('Public/Login')
        },
        {
          path: '/marketing/:token',
          beforeEnter: (to, from, next) => {
            console.log(from)
            next()
          },
          component: lazyLoad('Public/Marketing/')
        },
        {
          path: '/catalog',
          meta: { requiresAuth: true },
          component: lazyLoad('Common/Products/layout')
        },
        {
          path: '/products',
          component: lazyLoad('Public/Products/layout'),
          redirect: '/products/greetingcards',
          children: [
            {
              path: 'greetingcards',
              component: lazyLoad('Public/Products/GreetingCards')
            },
            {
              path: 'a3posters',
              component: lazyLoad('Public/Products/A3Posters')
            },
            {
              path: 'gicleeprints',
              component: lazyLoad('Public/Products/GicleePrints')
            },
            {
              path: 'smallmountedprints',
              component: lazyLoad('Public/Products/SmallMountedPrints')
            },
            {
              path: 'magnets',
              component: lazyLoad('Public/Products/Magnets')
            },
            {
              path: 'postcards',
              component: lazyLoad('Public/Products/Postcards')
            },
            {
              path: 'calendars',
              component: lazyLoad('Public/Products/Calendars')
            },
            {
              path: 'mugs',
              component: lazyLoad('Public/Products/Mugs')
            }
          ]
        },
        {
          path: '/artists',
          component: lazyLoad('Public/Artists/layout')
        },
        {
          path: '/artist/:artistCode',
          component: lazyLoad('Public/Artists/Artist/layout')
        },
        {
          path: '/retailers',
          component: lazyLoad('Public/Retailers/layout'),
          children: [
            {
              path: '',
              component: lazyLoad('Public/Retailers/Retailers')
            },
            {
              path: 'retailer/:id',
              component: lazyLoad('Public/Retailers/Retailer/layout')
            }
          ]
        },
        CustomerRoutes,
        AdminRoutes,
        PublishedArtistRoutes,
        UserRoutes,
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next(`/login?returnurl=${to.path}`)
  } else {
    next()
  }
})

export default router
