import ProjectAPI from '@/api/project'
// initial state
const state = () => ({
  projects: []
})

// getters
const getters = {
  projects: (state) => state.projects
}

// actions
const actions = {
  async getPublisherProjects ({ commit }) {
    const result = await ProjectAPI.getPublisherProjects()
    commit('setProjects', result.data)
  }
}

// mutations
const mutations = {
  setProjects (state, projects) {
    state.projects = projects
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
