import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/marketing/contact`

const loadContacts = () => {
  return axios.get(api)
}
const loadContact = (id) => {
  return axios.get(api + '/' + id)
}
const updateContacts = (contacts) => {
  return axios.post(api, contacts)
}

export default {
  loadContact,
  loadContacts,
  updateContacts
}
