import Vue from 'vue'
import Vuex from 'vuex'
import products from './modules/products'
import projects from './modules/projects'
import retailers from './modules/retailers'
import reports from './modules/reports'
import colours from './modules/colours'
import filters from './modules/filters'
import contacts from './modules/contacts'
import orders from './modules/orders'
import cart from './modules/cart'
import auth from './modules/auth'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import UserApi from '../api/user'
import { getCookie, setCookie } from '../utilities/cookieFunctions'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const server = process.env.VUE_APP_API_URL || ''

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    key: 'vuex',
    reducer (val) {
      if (val.token === '') {
        return {}
      }
      return val
    }
  })],
  modules: {
    cart,
    auth,
    products,
    projects,
    retailers,
    reports,
    colours,
    filters,
    orders,
    contacts
  },
  state: {
    currentRedirect: '/',
    status: '',
    token: sessionStorage.getItem('token') || '',
    user: Object,
    strict: debug,
    isLoggedIn: Boolean,
    returningVisitor: getCookie('returningVisitor')
  },
  mutations: {
    currentRedirect (state, path) {
      state.currentRedirect = path
    },
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, payload) {
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
    },
    auth_error (state) {
      state.status = 'error'
    },
    setUser(state, payload) {
      state.user = { ...state.user, ...payload }
    },
    logout (state) {
      state.status = ''
      state.token = ''
      state.user = ''
    }
  },
  actions: {
    setCurrentRedirect ({ commit }, path) {
      commit('currentRedirect', path)
    },
    logout ({ commit, dispatch }) {
      sessionStorage.removeItem('token')
      // delete axios.defaults.headers.common.Authorization
      commit('filters/clearAll')
      commit('logout')
    },
    login ({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: server + '/api/public/login/loginUser', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            const user = { ...resp.data.context }
            sessionStorage.setItem('token', token)
            // axios.defaults.headers.common.Authorization = 'Bearer: ' + token
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
            commit('auth_success', { token, user })
            commit('filters/setProductSearches', user.recentProductSearches)
            dispatch('auth/getToken')
            if ( ['CUSTOMER','ADMIN'].some(i => user.accessRoles.includes(i))) {
              dispatch('products/getAllProducts')
              dispatch('products/getArtists')
              dispatch('products/getProductTypes')
              dispatch('products/getProductTypeVariants')
            }
            if (user.accessRoles.includes('CUSTOMER')) {
              dispatch('cart/getCart')
            }
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            // delete axios.defaults.headers.common.Authorization
            sessionStorage.removeItem('token')
            reject(err)
          })
      })
    },
    async updateUser({ state, commit }, payload) {
      const updatedUser = { ...state.user, ...payload }
      commit('setUser', updatedUser)
      await UserApi.updateUser({...payload})
    },

  },
  getters: {
    accessRole: (state) => {
      return state.user.accessRole
    },
    preferences: (state) => {
      return state.user.preferences
    },
    isPublishedArtist: (state) => {
      return state.user.accessRoles && state.user.accessRoles.includes('PUBLISHED_ARTIST')
    },
    isCustomArtist: (state) => {
      return state.user.accessRoles && state.user.accessRoles.includes('CUSTOM_ARTIST')
    },
    isCustomer: (state) => {
      return state.user.accessRoles && state.user.accessRoles.includes('CUSTOMER')
    },
    isAdmin: (state) => {
      return state.user.accessRoles && state.user.accessRoles.includes('ADMIN')
    },
    hasXeroAccess: (state) => {
      return state.user.accessRoles && state.user.accessRoles.includes('XERO')
    },
    hasExclusiveProducts: (state) => {
      return state.user.hasExclusiveProducts
    },
    currentRedirect: state => state.currentRedirect,
    isLoggedIn: state => !!state.token,
    user: state => state.user,
    returningVisitor: state => state.returningVisitor
  }
})
