import store from '@/store'
import { lazyLoad } from '../index'
import Preferences from './preferenceRoutes'
import Artworks from './artworkRoutes'

export default {
  path: '/user',
  component: lazyLoad('User/layout'),
  meta: { requiresAuth: true },
  // beforeEnter: (to, from, next) => {
  //   if (store.getters.isLoggedIn) { next() } else { next('/') }
  // },
  redirect: 'user/account',
  children: [
    {
      path: 'account',
      component: lazyLoad('User/UserAccount')
    },
    Artworks,
    Preferences,
    {
      path: 'publicpage',
      component: lazyLoad('User/UserPublicPage')
    },
    {
      path: 'marketingpage',
      component: lazyLoad('User/UserMarketingPage')
    }
  ]
}
