import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/product`

const loadAggregates = (filter) => {
  return axios.post(api + '/loadAggregates', {
    ...filter
  })
}
const loadProducts = (filter, picksFilter, page, sort) => {
  return axios.post(api + '/loadProducts', {
    filter: { ...filter, ...picksFilter },
    page: page,
    sort: sort
  })
}
const getImageDataUrl = (url) => {
  return axios.post(api + '/imageDataUrl', {
    url: url
  })
}
const getArtists = () => {
  return axios.get(api + '/artists', {})
}
const loadArtistProducts = (artist) => {
  return axios.post(api + '/artistProducts', artist)
}

export default {
  getImageDataUrl,
  getArtists,
  loadArtistProducts,
  loadProducts,
  loadAggregates
}
