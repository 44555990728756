import { lazyLoad } from '../../index'

export default {
  path: 'reports',
  component: lazyLoad('Admin/Business/Report/layout'),
  redirect: 'reports/sales',
  children: [
    {
      path: 'sales',
      component: lazyLoad('Admin/Business/Report/Sales'),
      children: [
        {
          path: '',
          redirect: 'products'
        },
        {
          path: 'customers',
          component: lazyLoad('Admin/Business/Report/Sales/Customers')
        },
        {
          path: 'products',
          component: lazyLoad('Admin/Business/Report/Sales/Products')
        },
        {
          path: 'artists',
          component: lazyLoad('Admin/Business/Report/Sales/Artists')
        },
        {
          path: 'artworks',
          component: lazyLoad('Admin/Business/Report/Sales/Artworks')
        },
        {
          path: 'regions',
          component: lazyLoad('Admin/Business/Report/Sales/Regions')
        }
      ]
    },
    {
      path: 'artists',
      component: lazyLoad('Admin/Business/Report/Artists'),
      children: [
        {
          path: '',
          redirect: 'commission'
        },
        {
          path: 'commission',
          component: lazyLoad('Admin/Business/Report/Artists/Commission')
        }
      ]
    },
    {
      path: 'geo',
      component: lazyLoad('Admin/Business/Report/Geo'),
      children: [
        {
          path: '',
          redirect: 'retailers'
        },
        {
          path: 'retailers',
          component: lazyLoad('Admin/Business/Report/Geo/Retailers')
        },
        {
          path: 'artists',
          component: lazyLoad('Admin/Business/Report/Geo/Artists')
        }
      ]
    },
    {
      path: 'custom',
      component: lazyLoad('Admin/Business/Report/Custom'),
      children: [
        {
          path: '',
          redirect: 'customerorderhistory'
        },
        {
          path: 'customerorderhistory',
          component: lazyLoad('Admin/Business/Report/Custom/CustomerOrderHistory')
        }
      ]
    },
    {
      path: 'misc/:report',
      component: lazyLoad('Admin/Business/Report/Misc'),
    }
  ]
}
