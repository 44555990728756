import ProductApi from '@/api/product'
import ArtistApi from '@/api/public/artist'
import ProductTypeApi from '@/api/productTypes'
import ProductTypeVariantApi from '@/api/productTypeVariants'
// initial state
const state = () => ({
  products: [],
  productTypes: [],
  productTypeVariants: [],
  artists: [],
  picks: [],
  page: 1,
  totalProducts: 0,
  hasNextPage: true
})

// getters
const getters = {
  artists: (state) => state.artists
}

// actions
const actions = {
  resetPage ({ commit }) {
    commit('setPage', 1)
  },
  async getProductTypes ({ commit }, params = {}) {
    const result = await ProductTypeApi.loadProductTypes()
    commit('setProductTypes', [...result.data])
  },
  async getProductTypeVariants({ commit }, params = {}) {
    const result = await ProductTypeVariantApi.loadProductTypeVariants()
    commit('setProductTypeVariants', [...result.data])
  },
  async getAllProducts ({ commit }, params = { sort: {title: 1} }) {
    const result = await ProductApi.loadProducts(params.filter, params.picksFilter, params.page, params.sort)
    commit('setProducts', { products: result.data, infiniteContext: params.infiniteContext })
  },
  async getArtistProducts ({ commit }, params = {}) {
    const result = await ArtistApi.loadArtist(params)
    commit('setProducts', { products: result.data.products, infiniteContext: params.infiniteContext })
  },
  async getArtists ({ commit }, params = {}) {
    const result = await ProductApi.getArtists()
    commit('setArtists', result.data)
  }
}

// mutations
const mutations = {
  setArtists (state, artists) {
    state.artists = artists
  },
  setProducts (state, params) {
    const products = params.products.docs.map(item => {
      const isNew = item.truncatedDate ? new Date(item.truncatedDate) < new Date() : false
      return { ...item, isNew: isNew }
    })
    state.products.push(...products)
    state.totalProducts = params.products.totalDocs
    if (params.products.hasNextPage) {
      state.page = params.products.nextPage
      state.hasNextPage = params.products.hasNextPage
      params.infiniteContext && params.infiniteContext.loaded()
    } else {
      state.hasNextPage = false
      params.infiniteContext && params.infiniteContext.complete()
    }
  },

  clearProducts (state) {
    state.products = []
    state.page = 1
  },

  setPicks (state, data) {
    state.picks = data
  },
  setProductTypes(state, data) {
    state.productTypes = data
  },
  setProductTypeVariants(state, data) {
    state.productTypeVariants = data
  },
  setPage (state, page) {
    state.page = page
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
