import Cart from '../../api/customer/cart'
import Order from '../../api/order'
import _ from 'lodash'

// initial state
const state = () => ({
  items: [],
  delivery: 'COURIER',
  promo: {
    code: null
  }
})

// getters
const getters = {
  cart: (state) => state,
  totalCartItems: (state) => {
    return state.items ? state.items.length : 0
  },
  total: (state) => {
    let total = 0
    state.items && state.items.map((item) => {
      total += (item.unitDiscount ? item.unitPrice * item.qty * (100-item.unitDiscount)/100 : item.unitPrice * item.qty)
    })
    return total.toFixed(2)
  },
  validMugs (state) {
    let total = 0
    for (const item of state.items) {
      if (item.sku.startsWith('MUG')) {
        total += item.qty
      }
    }
    return (state.delivery === 'COLLECT') || (total % 36 === 0)
  },
  totalMugs (state) {
    let total = 0
    for (const item of state.items) {
      if (item.sku.startsWith('MUG')) {
        total += item.qty
      }
    }
    return total
  },
  totalProducts: (state) => {
    return state.items.length
  }
}

// actions
const actions = {
  async applyPromotion ({ state, commit }, promo) {
    if (promo) {
      state.promo = { ...promo }
    } else {
      state.promo = { code: null }
    }
    if (state.promo) {
      const currentItems = [ ...state.items ]
      currentItems.forEach( item => {
        const matchedProducts = state.promo.products.length === 0 || state.promo.products.includes(item.productType.productTypeCode)
        const matchedArtists = state.promo.artists.length === 0 || state.promo.artists.includes(item.artist.artistCode)
        if ( matchedProducts && matchedArtists) { item.unitDiscount = state.promo.discount } else { item.unitDiscount = null }
      })
      commit('setCartItems', currentItems)
      await Cart.updateCart(state)
    }
  },
  async clearPromotion ({ state, commit }) {
    commit('clearPromotion')
    await Cart.updateCart(state)
  },
  async updateCartItems ({ state, commit, dispatch }, items) {
    const currentItems = [ ...state.items ]
    const merged = _.values(_.merge(
      _.keyBy(currentItems, 'sku'),
      _.keyBy(items, 'sku')
    ))
    commit('setCartItems', merged)
    await Cart.updateCart(state)
    const cart = await Cart.getCart()
    commit('setCart', cart.data)
    dispatch('applyPromotion')
  },
  async copyOrderItems ({ state, commit }, items) {
    const currentItems = state.items
    const merged = _.values(_.merge(
      _.keyBy(currentItems, 'sku'),
      _.keyBy(items, 'sku')
    ))
    commit('setCartItems', merged)
    await Cart.updateCart(state)
  },
  updateCart ({ state, commit }, cart) {
    commit('setCart', cart)
  },
  removeItem ({ state, commit }, item) {
    commit('removeItem', item)
  },
  async submitCart ({ state, commit }, cart) {
    await Order.createOrder(cart)
    commit('clearCart')
    await Cart.updateCart(state)
  },
  async getCart ({ state, commit }) {
    const cart = await Cart.getCart()
    commit('setCart', cart.data)
  }
}

// mutations
const mutations = {
  clearCart (state) {
    state.items = []
    state.promo = null
  },
  clearPromotion (state) {
    const itemsToUpdate = [ ...state.items ]
    itemsToUpdate.forEach( item => item.unitDiscount = null )
    state.items = itemsToUpdate
    state.promo = { code: null }
  },
  removeItem (state, item) {
    _.remove(state.items, function(obj) {
      return obj.sku === item.sku
    })
  },
  setCart (state, cart) {
    state.items = cart.items
    state.delivery = cart.delivery
  },
  async setCartItems (state, items) {
    state.items = items
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
