import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/public/artist`

const loadArtists = () => {
  return axios.get(api, { })
}
const loadArtist = (artistCode) => {
  return axios.get(api + '/' + artistCode)
}

export default {
  loadArtists,
  loadArtist,
}
