import ContactAPI from '@/api/contact'
// initial state
const state = () => ({
  contacts: [],
  selected: null
})

// getters
const getters = {
  selected: (state) => state.selected,
  contacts: (state) => state.contacts,
}

// actions
const actions = {
  async loadContacts ({ commit }) {
    const result = await ContactAPI.loadContacts()
    const contacts = result.data.map(contact => {
      // contact.isSelectable = contact.marketingCategory.includes('UNDEFINED')
      return contact
    })
    commit('setContacts', contacts)
  },
  setSelected({ commit }, selected) {
    commit('setSelected', selected)
  }
}

// mutations
const mutations = {
  setContacts (state, contacts) {
    state.contacts = contacts
  },
  setSelected(state, selected) {
    state.selected = selected
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
