import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/auth`

const refreshToken = () => {
  return axios.get(api + '/refreshToken')
}

export default {
  refreshToken
}
