import { lazyLoad } from '../../index'

export default {
  path: 'marketing',
  component: lazyLoad('Admin/Business/Marketing/layout'),
  redirect: 'marketing/artists',
  children: [
    {
      path: 'contacts',
      component: lazyLoad('Admin/Business/Marketing/Contacts'),
    },
    {
      path: 'mailinglists',
      component: lazyLoad('Admin/Business/Marketing/MailingLists'),
    },
    {
      path: 'artists',
      component: lazyLoad('Admin/Business/Marketing/Artists'),
    },
    {
      path: 'products',
      component: lazyLoad('Admin/Business/Marketing/Products'),
    },
    {
      path: 'email',
      component: lazyLoad('Admin/Business/Marketing/Email'),
    },
    {
      path: 'brochures',
      component: lazyLoad('Admin/Business/Marketing/Brochures'),
    },
  ]
}
