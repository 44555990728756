import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/productTypeVariant`

const loadProductTypeVariants = () => {
  return axios.get(api, {})
}

const loadProductTypeVariantsForExport = () => {
  return axios.get(api + '/export', {})
}

const saveProductTypeVariant = (variant) => {
  return axios.post(api, { variant })
}

export default {
  loadProductTypeVariants,
  loadProductTypeVariantsForExport,
  saveProductTypeVariant
}
