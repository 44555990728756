import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Axios from 'axios'
import VueCountdownTimer from 'vuejs-countdown-timer'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import VueSanitize from "vue-sanitize"
import VueMeta from 'vue-meta'
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.prototype.$axios = Axios

Vue.prototype.$axios.interceptors.response.use((response) => {
  if (response.headers.token) {
    sessionStorage.setItem('token', response.headers.token)
    store.dispatch('auth/getToken')
  }
  return response
},
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('logout')
      router.push('/home')
    }
    return Promise.reject(error)
  })

Vue.prototype.$axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token')
  config.url.search(/amazonaws.com/)
  if (token) {
    if (config.url.search(/amazonaws.com/) === -1) {
      config.headers.Authorization = `Bearer ${token}`
    }
  }
  return config
},
  (error) => {
    return Promise.reject(error)
  })

Vue.mixin({
  methods: {
    getImageToDisplay: image => {
      if (image) {
        const sku = image.split('-')[0]
        switch (sku.slice(sku.length - 3)) {
          case ('OMP'):
            return image.replace('OMP', 'OP')
          case ('SMP'):
            return image.replace('SMP', 'SP')
          case ('MMP'):
            return image.replace('MMP', 'MP')
          case ('LMP'):
            return image.replace('LMP', 'LP')
          case ('XLMP'):
            return image.replace('XLMP', 'XLP')
          default:
            return image
        }
      }
      return image
    }
  }
})

Vue.component('customTextInput', () => import('@/components/vFormTextInput.vue'))
Vue.component('customSelect', () => import('@/components/vFormSelect.vue'))
Vue.component('userDebug', () => import('@/components/widgets/userDebug.vue'))
Vue.component('trueFalse', () => import('@/components/TrueFalseIcon.vue'))

Vue.config.productionTip = false
Vue.use(VueSanitize)
Vue.use(VueResource)
Vue.use(VueCountdownTimer)
Vue.use(VueMeta)
Vue.use(CKEditor)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
