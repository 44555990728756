<template>
  <v-main style="height: 100vh; margin-left: auto; margin-right:auto;">
  <div dark class="d-flex flex-column fill-height pa-4" style="max-width:1600px;">
    <div class="d-flex flex-row justify-space-between align-center pa-2" style="height: 15%;">
      <v-img height="90%" width="50%" position="center left" left contain src="@/assets/doricmor.png"></v-img>
      <v-btn color="success" to="/login" @click.stop="engage" class="mt-4 ml-4">
        Login
      </v-btn>
    </div>
    <v-row class="d-flex flex-row justify-space-between" style="height: 85%;">
      <v-col sm="12" md="6" class="fill-height">
      <v-card
        class=" d-flex flex-column fill-height"
        dark
        flat
        outlined
        style="overflow-y: auto;"
      >
        <v-card-text class="">
          <h3>
            Producers of high quality Fine Art merchandise and Giclee prints
            for:
          </h3>
          <v-row class="my-6">
          <v-col>
          <v-list>
            <v-list-item tag="h3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Visitor Attractions</v-list-item>
            <v-list-item tag="h3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Galleries</v-list-item>
            <v-list-item tag="h3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Gift Shops</v-list-item>
          </v-list>
          </v-col>
          <v-col>
          <v-list>
            <v-list-item tag="h3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Framers</v-list-item>
            <v-list-item tag="h3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Hospitality</v-list-item>
            <v-list-item tag="h3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Professional Artists</v-list-item>
          </v-list>
          </v-col>
          </v-row>
          <h3 class="mb-2">
            Choose from an extensive range of products featuring established artists.
            Or work with us to craft unique products for your business or institution.
          </h3>
          <h3>
            We offer a personal service, small product runs, and real quality merchandise, all produced in Scotland.
          </h3>
        </v-card-text>
          <v-spacer></v-spacer>
          <v-card-text>
          <v-list>
            <v-list-item>
              <v-icon class="mr-4" color="#018e4f"> mdi-phone </v-icon>
              +44 (0) 131 553 2772</v-list-item>
            <v-list-item>
              <v-icon class="mr-4" color="#018e4f"> mdi-email </v-icon>
              <a style="color: #ffffff" href="mailto://sales@doricmor.com">sales@doricmor.com</a></v-list-item>
          </v-list>
          </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="pb-0">
          <v-btn x-large block color="success" class="mb-4" to="/home" @click.stop="engage">
            Find Out More
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
      <v-col sm="12" md="6" class="fill-height">
        <v-card
          dark
          class="products fill-height"
          flat
            style="overflow-y: auto;"
        >
          <v-card-text class="fill-height" style="overflow-y: auto;">
            <template v-for="product of products">
              <h3 class="text-center mt-4 mb-2">{{ product.title }}</h3>
              <v-img contain class="mx-4" :src="product.image"></v-img>
            </template>
            <v-divider class="my-2"></v-divider>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </div>
</v-main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Fine Art Merchandise for visitor attractions, galleries and retail"
  },
  name: "Doricmor",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      productTypes: [
        "Giclee-Art-Prints",
        "Framed-Tiles",
        "Small-Mounted-Digital-Prints",
        "Stretched-Canvas",
        "Wood-Panel-Boards",
        "Acrylic-Blocks",
        "Glass-Cutting-Boards",
        "Greeting-Cards",
        "Coasters",
        "Mugs",
        "Postcards-incl-Packs",
        "Magnets",
        "Posters",
        "Calendars",
      ],
    };
  },
  mounted() {
  },
  methods: {
    engage() {
      const date = new Date();
      const days = 1000;
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = date.toGMTString();
      document.cookie =
        "returningVisitor=true; expires=" + expires + "; path=/;";
    },
    login() {
      const date = new Date();
      const days = 1000;
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = date.toGMTString();
      document.cookie =
        "returningVisitor=true; expires=" + expires + "; path=/;";
    },
    goToProducts(category) {
      const obj = {};
      obj.category = [category];
      this.$store.commit("filters/setFilters", obj);
      this.$router.push("/products").catch((err) => {
        return err !== null;
      });
    },
  },
  computed: mapState({
    ...mapGetters(["isLoggedIn"]),
    products: function () {
      return this.productTypes.map((product) => {
        return {
          title: product.replaceAll("-", " "),
          image: `${this.imageUrl}/Background-${product}-no-text-jpg.webp`,
        };
      });
    },
  }),
};
</script>
<style scoped>
.v-main,
.v-card {
  background-color: #1e1e1e;
  border: 1px solid #666;
  /* color: #ffffff */
}
</style>
