import store from '@/store'
import { lazyLoad } from './index'

export default {
  path: '/publishedartist',
  // meta: { requiresAuth: true },
  component: lazyLoad('PublishedArtist/ArtistView'),
  beforeEnter: (to, from, next) => {
    if (store.getters.isPublishedArtist) { next() } else { next('/') }
  },
  redirect: '/publishedartist/artworks',
  children: [
    {
      path: 'artworks',
      component: lazyLoad('PublishedArtist/Artworks/Artworks')
    }
  ]
}
