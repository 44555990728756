import store from '@/store'
import { lazyLoad } from '../../index'

export default {
  path: 'artworks',
  component: lazyLoad('Admin/Data/Artworks/List'),
  // children: [
  //   {
  //     path: '',
  //     component: lazyLoad('Admin/Data/Artworks/List')
  //   },
  //   {
  //     path: 'upload',
  //     component: lazyLoad('Admin/Data/Artworks/Upload')
  //   },
  //   {
  //     path: 'download',
  //     component: lazyLoad('Admin/Data/Artworks/Download')
  //   }
  // ]
}
