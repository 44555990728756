import store from '@/store'
import { lazyLoad } from '../index'

export default {
  path: 'preferences',
  component: lazyLoad('User/Preferences'),
  // meta: { requiresAuth: true },
  // beforeEnter: (to, from, next) => {
  //   if (store.getters.isLoggedIn) { next() } else { next('/') }
  // },
  redirect: 'preferences/marketing',
  children: [
    {
      path: 'marketing',
      component: lazyLoad('User/Preferences/Marketing')
    },
  ]
}
