import Auth from '../../api/auth'

// initial state
const state = () => ({
  token: sessionStorage.getItem('token')
})

// getters
const getters = {
  sessionExpiration: (state) => {
    const decoded = state.token && state.token !== 'undefined' ? JSON.parse(window.atob(state.token.split('.')[1])) : {}
    return decoded.exp ? decoded.exp * 1000 : 0
  }
}

// actions
const actions = {
  async getToken ({ commit }) {
    const token = sessionStorage.getItem('token')
    commit('setToken', token)
  },
  async clearToken ({ commit }) {
    commit('setToken', null)
  },
  async refreshToken ({ dispatch }) {
    await Auth.refreshToken()
    dispatch('getToken')
  }

}

// mutations
const mutations = {
  setToken (state, token) {
    state.token = token
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
