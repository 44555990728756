// initial state
const state = () => ({
  dateRange: 'thisQuarter',
  activeArtist: null,
  customRange: ['2021-12-01', '2023-11-19']
})

// getters
const getters = {
  activeArtist: (state) => state.activeArtist,
  dateRange: (state) => state.dateRange,
  customRange: (state) => state.customRange
}

// actions
const actions = {
  setActiveArtist ({ state, commit }, artist) {
    commit('setActiveArtist', artist)
  },
  setDateRange ({ state, commit }, dateRange) {
    commit('setDateRange', dateRange)
  },
  setCustomRange ({ state, commit }, customRange) {
    commit('setCustomRange', customRange)
  }
}
// mutations
const mutations = {
  setActiveArtist (state, artist) {
    state.activeArtist = artist
  },
  setDateRange (state, dateRange) {
    state.dateRange = dateRange
  },
  setCustomRange (state, customRange) {
    state.customRange = customRange
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
