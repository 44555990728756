<template>
  <v-dialog v-model="value" @click:outside="close" scrollable max-width="500px" >
    <v-card>
      <v-card-title class="mb-4 primary title white--text font-weight-bold">
        Session Time Out
      </v-card-title>
      <v-card-text>
      <vue-countdown-timer
        @start_callback="startCallBack('event started')"
        @end_callback="endCallBack('event ended')"
        :start-time='new Date(startTime)'
        :end-time="new Date(endTime)"
        :interval="1000"
      >
        <template slot="countdown" slot-scope="scope">
          <p>Due to inactivity, you will be logged out in {{ scope.props.minutes }} minutes, {{ scope.props.seconds }} seconds</p>
        </template>

      </vue-countdown-timer>

      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="refresh">Stay logged in</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script >
export default {
  name: 'Timer',
  props: {
    value: Boolean,
    endTime: Number,
    startTime: Number
  },
  computed: {
  },
  methods: {
    close () {
      this.$emit('close')
    },
    refresh () {
      this.$store.dispatch('auth/refreshToken')
    },
    startCallBack: function (x) {
      // this.$store.dispatch('auth/refreshToken')
    },
    endCallBack: function (x) {
      this.$emit('logout')
    }
  }
}
</script>
