import User from '../../api/user'

// initial state
const state = () => ({
  all: {},
  onlyPicks: false,
  searchText: null,
  productSearches: [],
  savedSearches: [],
  filtersChanged: false
})
const noFilters = function (state) {
  const filters = Object.keys(state)
  let total = 0
  for (const filter of filters) {
    total += state[filter] ? state[filter].length : 0
  }
  return total > 0
}

// getters
const getters = {
  onlyGreetingCards: (state) => state.all.category.length === 1 && state.all.category[0] === 'Greeting Cards',
  recentSearches: (state) => state.productSearches,
  filters: (state) => state.all,
  filtersChanged: (state) => state.filtersChanged
}

// actions
const actions = {
  async setFilters ({ state, commit }, obj) {
    commit('setFilters', obj)
    state.filtersChanged = noFilters(state.all)
  },
  async loadSavedFilters ({ state, commit }, obj) {
    commit('setFilters', obj)
    state.filtersChanged = false
  },
  async saveSearches ({ state, commit }, obj) {
    commit('addProductSearch')
    await User.updateUser({recentProductSearches: state.productSearches})
  }

}

// mutations
const mutations = {
  resetFilters (state) {
    state.all = {}
    state.filtersChanged = false
    state.searchText = null
  },
  clearAll (state) {
    state.all = {}
    state.onlyPicks = false
    state.searchText = null
    state.productSearches = []
    state.savedSearches = []
    state.filtersChanged = false
  },
  setFilters (state, filters) {
    const filter = Object.keys(filters)[0]
    if (filters[filter].length === 0) {
      this._vm.$delete(state.all, filter)
    } else {
      state.all = { ...state.all, ...filters }
    }
  },
  loadFilters (state, filters) {
    state.all = { ...filters }
  },
  setOnlyPicks (state, value) {
    state.onlyPicks = value
  },
  setSearchText (state, value) {
    state.all = { ...state.all, searchText: value }
  },
  setProductSearches (state, value) {
    state.productSearches = value
  },
  addProductSearch (state) {
    const search = JSON.stringify(state.all)
    state.productSearches.unshift(JSON.parse(search))
    state.filtersChanged = false
    state.productSearches = state.productSearches.slice(0, 10)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
