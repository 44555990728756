import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/productType`

const loadProductTypes = (filter) => {
  return axios.get(api, {...filter})
}
const saveProductType = (productType) => {
  return axios.post(api, { ...productType })
}

const loadProductTypesForExport = () => {
  return axios.get(api + '/export', {})
}

export default {
  saveProductType,
  loadProductTypes,
  loadProductTypesForExport
}
