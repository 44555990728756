import axios from 'axios'

const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/customer/cart`

const getCart = () => {
  return axios.get(api)
}

const getCartPDF = () => {
  return axios.get(api + '/pdf', { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `${+new Date()}.pdf`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
const updateCart = (cart) => {
  return axios.post(api, { cart })
}

export default {
  getCart,
  getCartPDF,
  updateCart
}
