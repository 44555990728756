import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/user/`
const publicApi = `${server}/api/public/`

const getUsers = () => {
  return axios.post(api + 'admin/loadUsers')
}

const getSubscriptionDetails = (token) => {
  return axios.get(publicApi + '/subscription/' + token)
}

const unsubscribeMarketingEmails = (token) => {
  return axios.post(publicApi + '/subscription/' + token)
}

const updateUser = (user) => {
  return axios.put(api, { ...user })
}

export default {
  getUsers,
  updateUser,
  getSubscriptionDetails,
  unsubscribeMarketingEmails
}
