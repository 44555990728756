import axios from 'axios'

const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/order`

const getOrders = () => {
  return axios.get(api)
}
const getUserOrder = (orderNumber) => {
  return axios.get(api + '/userOrder/' + orderNumber)
}
const getPDF = (orderNumber) => {
  return axios.get(api + `/pdf/${orderNumber}`, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Doricmor_Order_${orderNumber}.pdf`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
const getUserPDF = (orderNumber) => {
  return axios.get(api + `/userPdf/${orderNumber}`, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Doricmor_Order_${orderNumber}.pdf`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}

const getUserOrders = () => {
  return axios.get(api + '/user')
}

const countUserOrders = () => {
  return axios.get(api + '/countUserOrders')
}

const countUserOrderItems = () => {
  return axios.get(api + '/countUserOrderItems')
}

const createOrder = (cart) => {
  return axios.post(api, cart)
}

const updateOrderStatus = (id, status) => {
  // return axios.get(api + '/hasXeroConnection', { withCredentials: true, credentials: 'include' })
  return axios.put(api + '/orderStatus', { orderId: id, status: status }, { withCredentials: true, credentials: 'include' })
}

const getOrderStatuses = (id, status) => {
  return axios.get(api + '/orderStatuses')
}

export default {
  getOrders,
  getPDF,
  getUserPDF,
  getUserOrder,
  getUserOrders,
  getOrderStatuses,
  countUserOrders,
  countUserOrderItems,
  createOrder,
  updateOrderStatus
}
