// initial state
const state = () => ({
  colours: {
    Black: '#FFFFFF',
    Blue: '#0000FF',
    Brown: '#A52A2A',
    Green: '#008000',
    Grey: '#808080',
    Neutral: '#808080',
    Orange: '#FFA500',
    Pink: '#FFC05B',
    Purple: '#800080',
    Red: '#FF0000',
    Varied: '#808080',
    White: '#FFFFFF',
    Yellow: '#FFFF00'
  }
})

// getters
const getters = {}

// actions

export default {
  namespaced: true,
  state,
  getters
}
