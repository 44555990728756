import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/public/retailer`

const loadRetailers = ({ geo }) => {
  return axios.post(api, { geo: geo })
}
const loadRetailer = (id) => {
  return axios.get(api + '/' + id)
}

export default {
  loadRetailer,
  loadRetailers
}
