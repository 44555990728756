import store from '@/store'
import { lazyLoad } from '../../router/index'

export default {
  path: 'artworks',
  redirect: 'artworks/published',
  component: lazyLoad('User/UserArtworks'),
  children: [
    {
      path: 'published',
      component: lazyLoad('PublishedArtist/Artworks/Artworks')
    },
    {
      path: 'custom',
      component: lazyLoad('PublishedArtist/Artworks/Artworks')
    },
  ]
}
