import store from '@/store'

import { lazyLoad } from './index'

export default {
  path: '/customer',
  component: lazyLoad('Customer/layout'),
  meta: { requiresAuth: true },
  beforeEnter: (to, from, next) => {
    if (store.getters.isCustomer) { next() } else { next('/') }
  },
  children: [
    {
      path: '',
      component: lazyLoad('Customer/CustomerCart')
    },
    {
      path: 'orders',
      component: lazyLoad('Customer/CustomerOrders')
    },
    {
      path: 'products',
      component: lazyLoad('Customer/CustomerProducts')
    }
  ]
}
