import Vue from 'vue'
// import Vuetify from 'vuetify/lib/framework'
import Vuetify, {
    VRow,
    VCol,
    VTextField,
    VTextarea,
    VTooltip,
    VCheckbox,
    VSelect,
    VCard
} from 'vuetify/lib';
import { Ripple, Intersect, Touch, Resize } from 'vuetify/lib/directives';
import { hexToRGB } from '@/utilities/colour'

Vue.use(Vuetify, {
  components: { VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect, VCard, VTextarea },
    directives: { Ripple, Intersect, Touch, Resize },
});

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
  theme: {
    themes: {
      light: {
        primary: '#1a4b94',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
})
// src/plugins/vuetify.js

