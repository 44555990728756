import axios from 'axios'

const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/project`

const getPublisherProjects = () => {
  return axios.get(api + '/user')
}

const getPublisherProject = (id) => {
  return axios.get(api + '/user/' + id)
}

const savePublisherProject = (project) => {
  return axios.post(api + '/user', { project })
}

const getAdminProjects = () => {
  return axios.get(api + '/admin')
}

export default {
  getAdminProjects,
  getPublisherProjects,
  getPublisherProject,
  savePublisherProject
}
